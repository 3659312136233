// 链接以太坊网络的库
import { Web3Provider } from '@ethersproject/providers'
// 桌面使用，注入web3钱包连接器，用于与用户已经安装在浏览器中的钱包插件进行连接，比如 MetaMask
import { InjectedConnector } from '@web3-react/injected-connector'
// 移动应用适用，是一个用于与 WalletConnect 兼容的移动钱包进行连接的 Web3 连接器（如 Trust Wallet、MetaMask 移动版等）
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
// 适用移动设备上的 DApp，另一种用于建立与以太坊网络的连接的方法
// WalletLinkConnector 是通过 Coinbase Wallet 提供的 WalletLink 协议与以太坊网络建立连接的
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
// 是一个连接以太坊网络的选项，它允许用户使用 Portis 钱包进行身份验证和交易签名。Portis 是一个加密货币钱包服务提供商，它提供了一个简单易用的界面，使得用户可以方便地管理他们的数字资产。
import { PortisConnector } from '@web3-react/portis-connector'
// Fortmatic 钱包
import { FortmaticConnector } from './Fortmatic'
import { NetworkConnector } from './NetworkConnector'
import UNISWAP_LOGO_URL from '../assets/svg/logo.svg'

const NETWORK_URL = process.env.REACT_APP_NETWORK_URL
// FORMATIC钱包key
const FORMATIC_KEY = process.env.REACT_APP_FORTMATIC_KEY
// PORTIS钱包id
const PORTIS_ID = process.env.REACT_APP_PORTIS_ID
// WALLETCONNECT桥接url 
// WalletConnect Bridge URL 实际上是 WalletConnect 服务端的地址，用于处理用户与以太坊网络之间的通信
const WALLETCONNECT_BRIDGE_URL = process.env.REACT_APP_WALLETCONNECT_BRIDGE_URL

export const NETWORK_CHAIN_ID: number = parseInt(process.env.REACT_APP_CHAIN_ID ?? '1')

if (typeof NETWORK_URL === 'undefined') {
  throw new Error(`REACT_APP_NETWORK_URL must be a defined environment variable`)
}

export const network = new NetworkConnector({
  urls: { [NETWORK_CHAIN_ID]: NETWORK_URL }
})
// 用于存储网络连接库的实例。
let networkLibrary: Web3Provider | undefined
export function getNetworkLibrary(): Web3Provider {
  return (networkLibrary = networkLibrary ?? new Web3Provider(network.provider as any))
}

export const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42, 9738]
})
// 下面是链接各种钱包的配置，目前是只有主网才有
// mainnet only
export const walletconnect = new WalletConnectConnector({
  rpc: { 1: NETWORK_URL },
  bridge: WALLETCONNECT_BRIDGE_URL,
  qrcode: true,
  pollingInterval: 15000
})

// mainnet only
export const fortmatic = new FortmaticConnector({
  apiKey: FORMATIC_KEY ?? '',
  chainId: 1
})

// mainnet only
export const portis = new PortisConnector({
  dAppId: PORTIS_ID ?? '',
  networks: [1]
})

// mainnet only
export const walletlink = new WalletLinkConnector({
  url: NETWORK_URL,
  appName: 'Uniswap',
  appLogoUrl: UNISWAP_LOGO_URL
})
